import React from 'react';

import cx from 'classnames';

const LinkBtn = ({ className = '', href = '#', text = '' }) => (
  <a className={cx(className, 'btn')} href={href}>
    {text}
  </a>
);

export default LinkBtn;
