import React from 'react';

const Card = ({ img, title, rounded }) => {
  return (
    <div
      className={`flex flex-col p-6 text-center shadow-lg ${
        rounded ? 'rounded' : ''
      }`}
    >
      <dd className={`order-1 text-5xl font-extrabold text-indigo-400 `}>
        <img className="w-full lg:h-full" src={img} alt={title} />
      </dd>
      <dt
        className={`order-2 text-3xl leading-relaxed font-semibold text-coolGray-900 mt-1 ${
          !rounded ? 'mb-5' : 'mb-1'
        }`}
      >
        {title}
      </dt>
    </div>
  );
};

export default Card;
