import React from 'react';

import LinkBtn from '@components/link-btn';

const HeroSection = ({ title, subTitle, img }) => {
  return (
    <div className="pt-20 min-h-screen bg-coolGray-900 sm:pt-16 lg:pt-0 lg:pb-0 lg:overflow-hidden">
      <div className="lg:h-100v mx-auto max-w-8xl lg:px-0">
        <div className="lg:h-full lg:grid lg:grid-cols-2">
          <div className="px-4 max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:text-left lg:flex lg:items-center lg:px-8 lg:h-full">
            <div className="lg:py-24">
              <h1 className="mt-4 tracking-tight leading-none font-inter font-extraBold text-white text-6xl sm:mt-5 lg:mt-6">
                {title}
              </h1>
              <p className="mt-5 text-3xl leading-8 font-inter font-normal text-coolGray-200">
                {subTitle}
              </p>
              <div className="mt-14 sm:mt-12">
                <form action="#" className="sm:max-w-xl sm:mx-auto lg:mx-0">
                  <div className="sm:flex sm:justify-center md:justify-start">
                    <div className="sm:mt-0">
                      <LinkBtn
                        href="https://app.botswap.fi/"
                        text="Try app for free"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="mt-12 lg:m-0 lg:relative flex items-center">
            <img
              className="w-full lg:inset-y-0 lg:left-0 block lg:max-w-none"
              src={img}
              alt="Defi Agent Bot"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
